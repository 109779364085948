import { useSelector, useDispatch } from "react-redux";
import {
  GoodBg,
  ExcelentBg,
  NatijaImg,
  NotBad,
  StarsIcon,
  WpmIcon,
  CorrectIcon,
  MistakeIcon,
} from "../../../assets";

import { setGameStatus } from "../../../redux/reducers/statsReducer";
import { RestartImg, RefreshImg } from "../../../assets";
import axios from "axios";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
// import CountUp from "react-countup/build/CountUp";

// import {
//   LineChart,
//   Line,
//   CartesianGrid,
//   XAxis,
//   YAxis,
//   Tooltip,
// } from "recharts";
// import React from "react";
// import { Box } from "@mui/system";
// import { Tooltip } from "@mui/material";
// import { CHAR_TOOLTIP_TITLE } from "../../../constants/Constants";

const Stats = () => {
  // const data = [{ name: "Page A", uv: 400, pv: 2400, amt: 2400 }];

  const wpm = useSelector((state) => state.statistics.wpm);
  const inCorrectChars = useSelector(
    (state) => state.statistics.inCorrectChars
  );
  const correctChars = useSelector((state) => state.statistics.correctChars);
  const missedChars = useSelector((state) => state.statistics.missingChars);
  const totalChars = useSelector((state) => state.statistics.totalChars);

  const modme_id = localStorage.getItem("student_modme_id");
  const student_id = localStorage.getItem("student_id");
  const devStatus = localStorage.getItem("devStatus");
  const token = localStorage.getItem("token");

  // console.log("finish", modme_id, student_id, wpm);

  const [prevXp, setPrevXp] = useState(0);
  const [nowXp, setNowXp] = useState(0);

  const apiLink =
    devStatus !== "production"
      ? "https://test.api.marsit.uz/api/v1"
      : "https://api.marsit.uz/api/v1";

  useEffect(() => {
    if (wpm) {
      axios
        .post(
          `${apiLink}/student/student_experience`,
          {
            wpm: Math.round(wpm),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
        })
        .finally(async () => {
          axios
            .get(`${apiLink}/student/student_experience`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              setPrevXp(res.data.was);
              setNowXp(res.data.now);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [wpm]);

  const dispatch = useDispatch();

  return (
    <div className="typing-statistics-wrapper">
      <div className="typing-statistics">
        <div className="typing-statistics-header">
          <img src={StarsIcon} alt="stat-stars" />
          <img src={NatijaImg} alt="stat-natija" />
        </div>
        <div className="typing-stat-bg">
          <img src={GoodBg} alt="stat-bg" />
        </div>
        <div className="typing-statistics__secondary">
          {/* <h3>{correctChars} /</h3>
        <h3>{inCorrectChars} /</h3>
        <h3>{missedChars} /</h3>
        <h3>{totalChars}</h3> */}
          <div className="typing-statistics__stat-block">
            <img
              src={WpmIcon}
              alt="wpm-icon"
              className="typing-statistics__stat-block__img"
            />
            <div>
              <p className="typing-statistics__stat-block__title">WPM</p>
              <p className="typing-statistics__stat-block__text">
                {Math.round(wpm)}
              </p>
            </div>
          </div>
          <div className="typing-statistics__stat-block">
            <img
              src={CorrectIcon}
              alt="correct-icon"
              className="typing-statistics__stat-block__img"
            />
            <div>
              <p className="typing-statistics__stat-block__title">Correct</p>
              <p className="typing-statistics__stat-block__text">
                {correctChars}
              </p>
            </div>
          </div>
          <div className="typing-statistics__stat-block">
            <img
              src={MistakeIcon}
              alt="incorrect-icon"
              className="typing-statistics__stat-block__img"
            />
            <div>
              <p className="typing-statistics__stat-block__title">Mistake</p>
              <p className="typing-statistics__stat-block__text">
                {inCorrectChars}
              </p>
            </div>
          </div>
        </div>
        <div className="restart-block">
          <button
            className="restart-btn"
            onClick={() => {
              // dispatch(setGameStatus("waiting"));
              window.location.reload();
            }}
          >
            <img src={RestartImg} alt="restart" className="game-img" />
          </button>
        </div>
        {/* <LineChart
        width={600}
        height={300}
        data={data}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      >
        <Line type="monotone" dataKey="uv" stroke="#8884d8" />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
      </LineChart>
      <div className="typing-statistics__number">
        <div className="typing-statistics__number__wpm">
          <h3>WPM</h3>: <p> </p>
        </div>
      </div> */}
      </div>
      <div className="typing-statistics__student-xp">
        <div className="typing-statistics__student-xp__text-wrapper">
          <h2 className="typing-statistics__student-xp__title">Your xp:</h2>
          <CountUp className="total-xp" start={prevXp} end={nowXp} />
        </div>
      </div>
    </div>
  );
};

export default Stats;

// const renderLineChart = (

// );
