import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wpm: 0,
  correctChars: 0,
  inCorrectChars: 0,
  missingChars: 0,
  totalChars: 0,
  gameStatus: null,
};

const statsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    setWpm(state, action) {
      state.wpm = action.payload;
    },
    setCorrectChars(state, action) {
      state.correctChars = action.payload;
    },
    setInCorrectChars(state, action) {
      state.inCorrectChars = action.payload;
    },
    setTotalChars(state, action) {
      state.totalChars = action.payload;
    },
    setMissingChars(state, action) {
      state.missingChars = action.payload;
    },
    setGameStatus(state, action) {
      state.gameStatus = action.payload;
    },
  },
});

export const {
  setWpm,
  setCorrectChars,
  setInCorrectChars,
  setMissingChars,
  setTotalChars,
  setGameStatus,
} = statsSlice.actions;

export default statsSlice.reducer;
