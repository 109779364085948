import styled from "styled-components";
import IconButton from '@mui/material/IconButton';

export default styled(IconButton)`
  && {
    color: #BDBDBD;
    background-color: none;
    font-size: 16px;

  }
`;