import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
window.addEventListener("message", (event) => {
  if (
    event.origin === "https://test.space.marsit.uz" ||
    event.origin === "https://space.marsit.uz"
  ) {
    const {
      student_modme_id,
      student_id,
      student_name,
      student_surname,
      token,
      devStatus
    } = event.data;

    localStorage.setItem("student_modme_id", student_modme_id);
    localStorage.setItem("student_id", student_id);
    localStorage.setItem("student_name", student_name);
    localStorage.setItem("student_surname", student_surname);
    localStorage.setItem("token", token);
    localStorage.setItem("devStatus", devStatus);

  } else {
    return;
  }
});

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
