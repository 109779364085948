import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainPage from "./page/MainPage/ThemeProvider";

function App() {
  window.addEventListener("message", (event) => {
    if (
      event.origin === "https://test.space.marsit.uz" ||
      event.origin === "https://space.marsit.uz"
    ) {
      const {
        student_modme_id,
        student_id,
        student_name,
        student_surname,
        token,
      } = event.data;

      console.log(
        student_modme_id,
        student_id,
        student_name,
        student_surname,
        token,
        "app js"
      );
    } else {
      return;
    }
  });
  return (
    <Router basename="/">
      <Routes>
        <Route path="/" element={<MainPage />} />
      </Routes>
    </Router>
  );
}

export default App;
